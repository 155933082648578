// src/services/authService.js

import { CrmServiceClient } from '../crm_service_grpc_web_pb.js';
import { AuthenticateRequest } from '../crm_service_pb.js';

const client = new CrmServiceClient('https://peterhofcrm.tee-time.ru');

export function authenticateUser({ username, password }, callback) {
  let request = new AuthenticateRequest();
  request.setUsername(username);
  request.setPassword(password);

  client.authenticateUser(request, {}, (err, response) => {
    if (err) {
      console.error('gRPC error:', err);
      callback(err, null);
    } else {
      console.log('Received from gRPC:', response);
      callback(null, {
        token: response.getToken(), // Предположим, что ответ содержит токен
      });
    }
  });
}
